export const offerLinks =
    [
        {
            id: 1,
            path: "/strony-internetowe/",
            text: "Strony Internetowe",
        },
        {
            id: 2,
            path: "/sklepy-internetowe/",
            text: "Sklepy Internetowe",
        },
        {
            id: 3,
            path: "/grafika/",
            text: "Grafika",
        },
        {
            id: 4,
            path: "/pozycjonowanie-stron/",
            text: "Pozycjonowanie",
        },

        {
            id: 5,
            path: "/testowanie/",
            text: "Testowanie",
        },
    ]
