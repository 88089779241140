import React, { Component } from "react"
import { links } from "../navLinks.js"
import "../style/header.scss"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import MobileMenu from "./MobileMenu"
import HeaderDropdown from "./HeaderDropdown"
import { AiOutlineDown } from "react-icons/ai";


class Header extends Component {
  state = {
    isScrolled: false,
    isClicked: false,
    screenWidth: 0,
    openDropdown: false,
  }

  handleClick = () => {
    const { isScrolled, isClicked } = this.state

    this.setState({
      openDropdown: false,
      isClicked: !this.state.isClicked
    })

  }

  handleDropdownClick = () => {
    const { openDropdown } = this.state

    this.setState({ openDropdown: !openDropdown})
  }

  componentDidMount() {
    this.setState({
      screenWidth: window.innerWidth
    })
    window.addEventListener("scroll", (e) => {
      window.scrollY >= 100 ?
        this.setState({
          isScrolled: true,
        }) :
        this.setState({
          isScrolled: false
        })
    })
  }

  render() {
    const { openDropdown } = this.state
    console.log(openDropdown)

    const homeLinks = links.map(link => {
      return <li className="menu__item" key={link.id}>
        <AnchorLink
          to={link.path}
          className="menu__link"
        >
          {link.text}
        </AnchorLink>
      </li>
    })

    const sideLinks = links.map(link => {
      return (<li className="menu__item" key={link.id}>
          {link.text.toLowerCase() == "oferta" ?
            <span className="menu__link" onClick={this.handleDropdownClick}>
              {link.text} <AiOutlineDown className={ openDropdown ? "isOpen" : ""}/>
            </span> :
            <AniLink
              cover to={link.path} bg="#000" duraction={1} className="menu__link">
              {link.text}
            </AniLink>
          }
        </li>
      )
    })
    return (
      <nav className={`menu ${this.state.isScrolled ? "scrolled" : ""} ${this.state.isClicked ? "clicked" : ""}`}>
        <div className="menu__logo">
                <span className="menu__logo-name">
                    <AniLink
                      cover to="/" bg="#000" duraction={1} className="menu__logo-link">
                      {!this.state.isScrolled ? "CodersBros" : "CB"}
                    </AniLink>
                </span>
        </div>
        {this.state.screenWidth > 991 ?
          <div className={`menu__item-list`}>
            {this.props.isHomePage ? homeLinks : sideLinks}
            <HeaderDropdown isOpen={openDropdown}/>
          </div> :
          <MobileMenu/>
        }
        {this.state.isScrolled && this.state.screenWidth > 991 ?
          <button className="menu__button" onClick={this.handleClick}>MENU</button> : ""}
      </nav>
    )
  }
}

export default Header