export const links =
    [
        {
            id: 1,
            path: "/#about",
            text: "O nas",
        },
        {
            id: 2,
            path: "/#oferta",
            text: "Oferta",
        },
        // {
        //     id: 3,
        //     path: "/#portfolio",
        //     text: "Portfolio",
        // },
        {
            id: 3,
            path: "/#kontakt",
            text: "Kontakt",
        },
    ]
