import React from 'react';
import "./style/footer.scss"
import {links} from "./navLinks.js"
import {offerLinks} from "./offerLinks.js"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import {AnchorLink} from "gatsby-plugin-anchor-links";



function Footer(props) {
    const homeLinks = links.map(link => {
        return <li className="footer__item" key={link.id}>
            <AnchorLink
                to={link.path}
                className="footer__link"
            >
                {link.text}
            </AnchorLink>
        </li>
    })

    const sideLinks = links.map(link => {
        return <li className="footer__item" key={link.id}>
            <AniLink
                cover to={link.path} bg="#000" dduraction={1} className="footer__link">
                {link.text}
            </AniLink>
        </li>
    })
    return (
        <footer className="footer">
            <div className="footer__inner">
                <span>CodersBros</span>
                <h5>Kielce, Świetokrzyskie</h5>
            </div>
            <div className="footer__inner">
                <ul>
                    <li className="footer__item">
                        <AniLink className="footer__link" to="/">
                          Główna
                        </AniLink>
                    </li>
                    {props.isHomePage ? homeLinks : sideLinks}
                </ul>
            </div>
            <div className="footer__inner">
                <ul>
                    {offerLinks.map(link => {
                        return <li className="footer__item" key={link.id}>
                            <AniLink
                                cover to={link.path} bg="#000" dduraction={1} className="footer__link">
                                {link.text}
                            </AniLink>
                        </li>
                    })}
                </ul>
            </div>
            <div className="footer__inner">
                <p className="footer__text blank">
                  {props.desc}
                </p>
            </div>
        </footer>
    );
}

export default Footer;