import React from "react"
import {offerLinks} from "../offerLinks.js"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const offerLinksItem = offerLinks.map(link => {
  return <li className="headerDropdown__item" key={link.id}>
    <AniLink
      cover to={link.path} bg="#000" dduraction={1} className="headerDropdown__link">
      {link.text}
    </AniLink>
  </li>
})

function HeaderDropdown({ isOpen }) {
  console.log(isOpen)
  return (
    <div className={`headerDropdown ${isOpen ? "isOpen" : ""}`}>
      <ul className="headerDropdown__list">
        {offerLinksItem}
      </ul>
    </div>
  )
}

export default HeaderDropdown