import React, { Component } from "react"
import "../style/mobileMenu.scss"
import { links } from "../navLinks"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { offerLinks } from "../offerLinks.js"

class MobileMenu extends Component {
  state = {
    isClicked: false
  }

  handleClick = () => {
    this.setState((prevState) => {
      return {
        isClicked: !prevState.isClicked
      }
    })
  }

  render() {
    const { isHomePage } = this.props
    const homeLinks = links.map(link => {
      return <li className="list__item" key={link.id} >
        <AnchorLink
          to={link.path}
          className="item__link"
          stripHash
        >
          {link.text}
        </AnchorLink>
      </li>
    })

    const sideLinks = links.map(link => {
      return <li className="list__item" key={link.id}>
        <AniLink
          cover to={link.path} bg="#000" duraction={1} className="item__link">
          {link.text}
        </AniLink>
      </li>
    })

    const offerLinksItem = offerLinks.map(link => {
      return <li className="list__item" key={link.id}>
        <AniLink
          cover to={link.path} bg="#000" dduraction={1} className="item__link">
          {link.text}
        </AniLink>
      </li>
    })

    return (
      <div className={`mobileMenu ${this.state.isClicked ? "clicked" : ""}`}>
        <button className={`mobileMenu__button ${this.state.isClicked ? "clicked" : ""}`} onClick={this.handleClick}>
          <span></span>
        </button>
        <div className={`mobileMenu__list ${this.state.isClicked ? "clicked" : ""}`}>
          <div className="mobileMenu__logo">
            <AniLink
              cover to="/" bg="#000" duraction={1} className="offerLink__link">
                CodersBros
            </AniLink>
          </div>
          <ul onClick={this.handleClick}>
            {!isHomePage ? homeLinks : sideLinks}
            {!isHomePage ? offerLinksItem : ""}
          </ul>
        </div>
      </div>
    )
  }
}

export default MobileMenu