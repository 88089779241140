/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import "./style/layout.css"
import Header from "./Header/Header";
import Footer from "./Footer";

const Layout = ({ children, isHomePage, footerDesc, page }) => {

  return (
    <div className={`layout__wrapper ${page}`}>
      <Header isHomePage={isHomePage}/>
            {children}
        <Footer isHomePage={isHomePage} desc={footerDesc}/>
    </div>
  )
}


export default Layout
